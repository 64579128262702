<template>
  <div class="rf-wizard" :style="wizardStyle">
    <div class="wizard-header">
      <div v-for="(step, index) in steps" :key="`${step.name}-${index}`" class="step-name"
           :class="{ active: index + 1 === currentStep }">
        <span class="step-number d-md-none" :class="{
          'd-block': index + 1 !== currentStep,
          'd-none': index + 1 === currentStep,
        }">{{ index + 1 }}</span>
        <span class="title d-md-block" :class="{ 'd-none': index + 1 !== currentStep }">{{ $t(step.name) }}</span>
      </div>
    </div>
    <div class="wizard-content">
      <ValidationObserver ref="validator">
        <form class="rf-white-background" @submit.prevent="bookingRequest">
          <!-- DATE STEP -->
          <section class="reservation-date" v-if="currentStep === 1">
            <b-container>
              <b-row class="mb-4">
                <b-col>
                  <h1 class="step-title">{{ $t('How long do you want to stay?') }}</h1>
                </b-col>
              </b-row>
              <b-row class="pb-5 pb-lg-0">
                <b-col cols="12" md="6" lg="5">
                  <RFValidation rules="required" :name="$t('Arrival')">
                    <RFMonthPicker :color="isApartment ? '#67AEA6' : '#E26F5A'" :label="$t('Arrival')"
                                   :occuped-periods="occupedPeriods" v-model="form.startDate" :min="nextMonth(null)"
                                   :max="maxArrivalDate ? maxArrivalDate : null" :special-format="specialDateFormat"/>
                  </RFValidation>
                </b-col>
                <b-col cols="12" md="6" lg="5" class="mt-3 mt-md-0">
                  <RFValidation rules="required" :name="$t('Departure')">
                    <RFMonthPicker :color="isApartment ? '#67AEA6' : '#E26F5A'" :label="$t('Departure')"
                                   :occuped-periods="occupedPeriods" v-model="form.endDate"
                                   :min="minStayMonth || nextMonth(form.startDate)"
                                   :max="maxDepartureDate ? maxDepartureDate : null"
                                   :special-format="specialDateFormat" :ending-date="true"/>
                  </RFValidation>
                </b-col>
                <b-col v-if="item.numOfPerson > 1" cols="12" md="6" lg="5" class="mt-3">
                  <RFValidation rules="required" name="Guests">
                    <RFInput :color="isApartment ? '#67AEA6' : '#E26F5A'" label="Guests" type="number" :min="1"
                             :max="item.numOfPerson" v-model="roomGuests"/>
                  </RFValidation>
                </b-col>
              </b-row>
            </b-container>
          </section>
          <!-- SUMMARY STEP -->
          <section class="reservation-summary" v-if="currentStep === 2">
            <b-container>
              <b-row>
                <b-col>
                  <h1 class="step-title mb-4">{{ $t('Check your reservation') }}</h1>
                </b-col>
              </b-row>
              <b-row class="reservation-summary-header">
                <b-col cols="12" md="7" order="1" order-md="0">
                  <h1 class="accommodation" v-if="item.type.title">{{ item.type.title | toUpperCase }}</h1>
                  <h1 class="accommodation subtitle" v-if="item.type.subtitle">
                    {{ item.type.subtitle | toUpperCase }}</h1>
                  <div class="reservation-guests">
                    <FontAwesomeIcon :icon="['fas', 'angle-right']" class="mr-2"/>
                    {{ $t('Guest') }}
                    <FontAwesomeIcon class="ml-2" color="#24425B" :icon="['fas', 'user']"
                                     v-for="(guest, index) in guests" :key="index"/>
                  </div>
                  <div class="reservation-period">
                    <FontAwesomeIcon :icon="['fas', 'angle-right']" class="mr-2"/>
                    {{ $t('Period') }}
                    <span class="period ml-2">
                      {{ form.startDate | extractMonthLocaleFromDate }} {{ form.startDate | extractYearFromDate }} -
                      {{ form.endDate | extractMonthLocaleFromDate }} {{ form.endDate | extractYearFromDate }}
                    </span>
                  </div>
                </b-col>
                <b-col cols="12" md="5" order="0" order-md="1" class="mb-4 mb-md-0">
                  <VueperSlides :slide-ratio="0.5" :arrows="true" :bullets="false" infinite :touchable="false">
                    <VueperSlide v-for="(image, index) in item.images" :key="index">
                      <template v-bind:class="[ image.pathname.split('.')[1]==='mp4' ? 'video-container' : '']"
                                #content>
                        <div v-if="image.pathname.split('.')[1]!=='mp4'" class="vueperslide__content-wrapper">
                          <div v-if="item.buildingId">
                            <b-img :src="image.smallBuildingImageFilepath || null | getAsset" class="sliderCursor" fluid
                                   @click="showModal(index)"/>
                          </div>
                          <div v-else>
                            <b-img :src="image.smallApartmentImageFilepath || null | getAsset" class="sliderCursor" fluid
                                   @click="showModal(index)"/>
                          </div>
                        </div>
                        <video v-if="image.pathname.split('.')[1]==='mp4'" autoplay muted loop playsinline fluid
                               class="sliderCursor" @click="showModal(index)">
                          <source :src="image.pathname | getAsset" type="video/mp4">
                        </video>
                      </template>
                    </VueperSlide>
                  </VueperSlides>
                </b-col>
              </b-row>
              <b-row>
                <b-col class="reservation-amounts">
                  <b-col cols="12" md="6" lg="6">
                    <div class="price-label">
                      <div class="label">{{ $t('Price') }}
                        <FontAwesomeIcon v-if="!categoriesInfo['Price'].visible"
                                         class="cursor-pointer ml-1 description-icon" :icon="['fas', 'chevron-down']"
                                         @click="openCategoryDescription('Price')"/>
                        <FontAwesomeIcon v-if="categoriesInfo['Price'].visible"
                                         class="cursor-pointer ml-1 description-icon" :icon="['fas', 'chevron-up']"
                                         @click="closeCategoryDescription('Price')"/>
                      </div>
                      <div class="price">
                        {{ item.monthlyAmount }} &euro;
                        <template v-if="item.type.isShared">
                          {{ $t('per person') }}
                        </template>
                      </div>
                    </div>
                  </b-col>
                  <b-col cols="12">
                    <div v-if="categoriesInfo['Price'].visible">
                      <p class="description" v-for="descriptionParagraph in categoriesInfo['Price'].description"
                         :key="descriptionParagraph">
                        {{ $t(descriptionParagraph) }}
                      </p>
                    </div>
                  </b-col>
                  <b-col cols="12" md="6" lg="6">
                    <div class="price-deposit">
                      <div class="label">{{ $t('Deposit') }}
                        <FontAwesomeIcon v-if="!categoriesInfo['Deposit'].visible"
                                         class="cursor-pointer ml-1 description-icon" :icon="['fas', 'chevron-down']"
                                         @click="openCategoryDescription('Deposit')"/>
                        <FontAwesomeIcon v-if="categoriesInfo['Deposit'].visible"
                                         class="cursor-pointer ml-1 description-icon" :icon="['fas', 'chevron-up']"
                                         @click="closeCategoryDescription('Deposit')"/>
                      </div>
                      <div class="price">
                        {{ item.depositAmount }} &euro;
                        <template v-if="item.type.isShared">
                          {{ $t('per person') }}
                        </template>
                      </div>
                    </div>
                  </b-col>
                  <b-col cols="12">
                    <div v-if="categoriesInfo['Deposit'].visible">
                      <p class="description" v-for="descriptionParagraph in categoriesInfo['Deposit'].description"
                         :key="descriptionParagraph">{{ $t(descriptionParagraph) }}</p>
                    </div>
                  </b-col>
                  <b-col cols="12" md="6" lg="6">
                    <div class="price-service-fees">
                      <div class="label">{{ $t('Service Fees') }}
                        <FontAwesomeIcon v-if="!categoriesInfo['Service Fees'].visible"
                                         class="cursor-pointer ml-1 description-icon" :icon="['fas', 'chevron-down']"
                                         @click="openCategoryDescription('Service Fees')"/>
                        <FontAwesomeIcon v-if="categoriesInfo['Service Fees'].visible"
                                         class="cursor-pointer ml-1 description-icon" :icon="['fas', 'chevron-up']"
                                         @click="closeCategoryDescription('Service Fees')"/>
                      </div>
                      <div class="price">{{ item.serviceFees }} &euro;</div>
                    </div>
                  </b-col>
                  <b-col cols="12">
                    <div v-if="categoriesInfo['Service Fees'].visible">
                      <p class="description" v-for="descriptionParagraph in categoriesInfo['Service Fees'].description"
                         :key="descriptionParagraph">{{ $t(descriptionParagraph) }}</p>
                    </div>
                  </b-col>
                </b-col>
              </b-row>
              <!-- <b-row class="transform-building">
                <b-col>
                  <span class="text-uppercase">{{$t('Transform your')}} </span>
                  <span class="montserrat-bold">{{$t('Small Roof')}} </span>
                  <span>{{$t('into a')}} </span>
                  <span class="montserrat-bold">{{`${$t('Big Roof')} +`}}</span>
                </b-col>
              </b-row> -->
              <b-row class="reservation-price px-3 py-0 py-4">
                <b-col class="mt-2 mt-md-0 px-0" cols="12" md="6" order="1" order-md="0">
                  <div class="reservation-total-price text-right text-md-left">
                    <div class="total-label">{{ $t('Total to secure booking') }}
                      <FontAwesomeIcon v-if="!categoriesInfo['Total'].visible"
                                       class="cursor-pointer ml-1 description-icon" :icon="['fas', 'chevron-down']"
                                       @click="openCategoryDescription('Total')"/>
                      <FontAwesomeIcon v-if="categoriesInfo['Total'].visible"
                                       class="cursor-pointer ml-1 description-icon" :icon="['fas', 'chevron-up']"
                                       @click="closeCategoryDescription('Total')"/>
                    </div>
                    <span class="total-price">&euro; {{ item.depositAmount + item.serviceFees }}</span>
                  </div>
                </b-col>
                <b-col class="px-0" cols="12" md="6" order="0" order-md="1">
                  <div class="make-booking-request text-md-right mb-3 mb-md-0">
                    <p class="make-request">{{ $t('Make my booking request', {bookingRequest: $t('Booking request')}) }}
                    </p>
                    <p class="make-request-text">{{ $t('Nothing won\'t be charged at this point') }}</p>
                  </div>
                </b-col>
              </b-row>
              <b-row class="reservation-price">
                <b-col cols="12">
                  <div v-if="categoriesInfo['Total'].visible">
                    <p class="description" v-for="descriptionParagraph in categoriesInfo['Total'].description"
                       :key="descriptionParagraph">
                      {{ $t(descriptionParagraph) }}
                    </p>
                  </div>
                </b-col>
              </b-row>
            </b-container>
          </section>
          <!-- DETAILS STEP -->
          <section class="reservation-details" v-if="currentStep === 3 && !addingGuest">
            <b-container v-if="!reservationCompleted && !loading && !hasError">
              <b-row>
                <b-col>
                  <h1 class="step-title mb-2">{{ $t('Tell us more about you') }}</h1>
                </b-col>
              </b-row>
              <b-row v-if="roomGuests > 1">
                <b-col class="d-flex align-items-center">
                  <div class="added-guests" v-if="!addingGuest && !deletingGuest">
                    <FontAwesomeIcon class="cursor-pointer mx-1" :icon="['fas', 'user']"
                                     v-for="(guest, index) in guests" :key="index"
                                     :color="index < numOfGuests ? isApartment ? '#67AEA6' : '#E26F5A' : '#DADBD7'"
                                     @click="editGuest(index)"/>
                  </div>
                  <div class="delete-guest ml-4" :style="{
                    color: isApartment ? '#67AEA6' : '#E26F5A',
                  }" @click="deleteGuest" v-if="form.guests.length > 1">
                    <FontAwesomeIcon :icon="['fas', 'times']"/>
                    <div class="delete-guest-label">{{ $t('Delete guest', {guest: $t('guest')}) }}</div>
                  </div>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12" md="6">
                  <RFValidation rules="required" :name="$t('Firstname')">
                    <RFInput :color="isApartment ? '#67AEA6' : '#E26F5A'" :label="$t('Firstname')"
                             v-model="guestFields.firstname" :value="guestFields.firstname" :readonly="readonly"/>
                  </RFValidation>
                </b-col>
                <b-col cols="12" md="6">
                  <RFValidation rules="required" :name="$t('Lastname')">
                    <RFInput :color="isApartment ? '#67AEA6' : '#E26F5A'" :label="$t('Lastname')"
                             v-model="guestFields.lastname" :value="guestFields.lastname" :readonly="readonly"/>
                  </RFValidation>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12" md="6">
                  <RFValidation rules="required" :name="$t('Birth Date')" mode="eager">
                    <RFDatePicker :label="$t('Birth Date')" :color="isApartment ? '#67AEA6' : '#E26F5A'"
                                  v-model="guestFields.birthDate"
                                  :readonly="this.user && this.user.birthdate ? readonly : false"/>
                  </RFValidation>
                </b-col>
                <b-col cols="12" md="6">
                  <RFValidation rules="required" :name="$t('Nationality')" mode="eager">
                    <RFInputAutocomplete :color="isApartment ? '#67AEA6' : '#E26F5A'" :label="$t('Nationality')"
                                         :items="extractedNationalities" v-model="guestFields.nationality"
                                         :value="guestFields.nationality"
                                         :disabled="this.user && this.user.nationality ? readonly : false"/>
                  </RFValidation>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12" md="6">
                  <RFValidation rules="required|email" :name="$t('Email')">
                    <RFInput :color="isApartment ? '#67AEA6' : '#E26F5A'" :label="$t('Email')"
                             v-model="guestFields.email" :value="guestFields.email" :readonly="readonly"/>
                  </RFValidation>
                </b-col>
                <b-col cols="12" md="6">
                  <RFRadio :label="$t('Gender')" name="gender" v-model="guestFields.genre"
                           :list="[{ label: 'M', value: 'MALE' }, { label: 'F', value: 'FEMALE' }]"
                           :color="isApartment ? '#67AEA6' : '#E26F5A'"
                           :disabled="this.user && this.user.genre ? readonly : false"/>
                </b-col>
              </b-row>
              <b-row class="">
                <b-col cols="12" md="4">
                  <RFValidation rules="required" :name="$t('Country Code')" mode="eager">
                    <RFValidation rules="required" :name="$t('Country Code')" mode="eager">
                      <RFInputAutocomplete :color="isApartment ? '#67AEA6' : '#E26F5A'" :label="$t('Country Code')"
                                           :items="extractedCountryCode" v-model="guestFields.countryCode"
                                           :value="guestFields.countryCode"
                                           :disabled="this.user && this.user.countryCode ? readonly : false"/>
                    </RFValidation>
                  </RFValidation>
                </b-col>
                <b-col cols="12" md="8">
                  <RFValidation rules="required|phoneNumber" :name="$t('Whatsapp Contact')" mode="eager">
                    <RFInput :color="isApartment ? '#67AEA6' : '#E26F5A'" :label="$t('Whatsapp Contact')"
                             v-model="guestFields.whatsappContact" :value="guestFields.whatsappContact"
                             :readonly="this.user && this.user.whatsappContact ? readonly : false" is-numeric/>
                  </RFValidation>
                </b-col>
                <b-col cols="12">
                  <RFRadio :label="$t('What do you do?')" name="employment" v-model="guestFields.employment"
                           :list="[{ label: 'Study', value: 'study' }, { label: 'Work', value: 'work' }]"
                           :color="isApartment ? '#67AEA6' : '#E26F5A'"
                           :disabled="this.user && this.user.employment ? readonly : false"/>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <span class="just-title">{{ $t('Tell us more about you') }}</span>
                  <RFValidation rules="max:1000" :name="$t('Tell us more about you')" mode="aggressive">
                    <RFTextArea class="mb-2" :color="isApartment ? '#67AEA6' : '#E26F5A'" v-model="guestFields.tellUs"
                                :value="guestFields.tellUs"
                                :readonly="this.user && this.user.tellus ? readonly : false"/>
                  </RFValidation>
                </b-col>
              </b-row>

              <div class="add-guest" @click="addGuest" v-if="form.guests.length < guests">
                <FontAwesomeIcon :icon="['fas', 'user-plus']" class="mr-2"/>
                {{ $t('Add another guest', {guest: $t('guest')}) }}
              </div>
            </b-container>

            <template v-if="loading">
              <b-row class="loader-container">
                <b-col cols="auto">
                  <RFLoader :color="isApartment ? '#67AEA6' : '#E26F5A'"/>
                </b-col>
                <b-col class="loader-label">
                  {{ `${$t('Wait while your booking request is being processed', {bookingRequest: $t('Booking request')})}` }}
                </b-col>
              </b-row>
            </template>
            <template v-else>
              <h1 class="step-title text-center mt-5 pb-5 px-3" v-if="hasError">
                <p class="mb-0">
                  {{ $t('There was an error on booking request', {bookingRequest: $t('Booking request')}) }}
                </p>
                <p>{{ $t('Please try again') }}</p>
              </h1>
            </template>
          </section>
          <!-- CHECKOUT STEP -->
          <section class="reservation-checkout" v-if="currentStep === 4">
            <h1 class="checkout-title">{{ `${$t('We\'re almost there')}!` }}</h1>
            <h1 class="step-title checkout">
              {{ $t('Thanks so much for your request!', {bookingRequest: $t('Booking request')}) }}
              <p>{{ $t('We\'ll be back soon') }}</p>
            </h1>
            <div class="text-center mb-5 mb-lg-0">
              <router-link :to="user ? '/profile/your-roof' : '/'">
                <RFButton :label="user ? 'Back to your profile' : 'Back to Homepage'"
                          :background="isApartment ? '#67AEA6' : '#E26F5A'" size="small"/>
              </router-link>
            </div>
          </section>
        </form>
      </ValidationObserver>
    </div>

    <div class="wizard-footer" :class="{ 'last-step': currentStep === steps.length && !hasError }"
         v-if="!loading && !hasError">
      <b-col cols="auto" v-if="currentStep !== steps.length">
        <RFButton :background="isApartment ? '#67AEA6' : '#E26F5A'" color="#fff" border="#fff" @on-click="prevStep"
                  label="Back"
                  v-if="((reservationType === accommodations.APARTMENT ? currentStep > 2 : currentStep > 1) && currentStep < steps.length)"/>
      </b-col>
      <b-col cols="auto" v-if="currentStep !== steps.length">
        <RFButton :background="isApartment ? '#67AEA6' : '#E26F5A'" color="#fff" border="#fff" @on-click="nextStep"
                  label="Next" v-if="currentStep < steps.length"/>
      </b-col>
      <b-col class="d-flex flex-column align-items-center" v-if="currentStep === steps.length && !hasError">
        <img src="@/assets/images/gray_roofs.png" class="w-25 mb-3"/>
        <div class="verify-email px-3">
          {{ $t('Please verify your email and get access to your own personal space where you\'ll find your contract.') }}
        </div>
      </b-col>
    </div>
    <CarouselModal/>
  </div>
</template>

<script>
import RFInput from '@/components/forms/RFInput'
import RFButton from '@/components/forms/RFButton'
import RFRadio from '@/components/forms/RFRadio'
import RFTextArea from '@/components/forms/RFTextArea'
import RFDatePicker from '@/components/forms/RFDatePicker'
import RFMonthPicker from '@/components/forms/RFMonthPicker'
import RFLoader from '@/components/forms/RFLoader'
import RFInputAutocomplete from '@/components/forms/RFInputAutocomplete'
import RFValidation from '@/components/forms/RFValidation'

import {guestService, roomService} from '@/services'
import {accommodations, nationalityTypes} from '@/enums'

import moment from 'moment'
import {mapState} from 'vuex'
import CarouselModal from '../modals/CarouselModal.vue'
import {getAsset} from "@/helpers";

export default {
  components: {
    RFButton,
    RFInput,
    RFRadio,
    RFTextArea,
    RFDatePicker,
    RFMonthPicker,
    RFLoader,
    RFInputAutocomplete,
    RFValidation,
    CarouselModal
  },
  props: {
    item: Object,
  },
  data() {
    return {
      steps: [
        {name: 'Date'},
        {name: 'Summary'},
        {name: 'Details'},
        {name: 'Checkout'},
      ],

      accommodations,

      guestFields: {
        firstname: null,
        lastname: null,
        birthDate: null,
        genre: null,
        nationality: null,
        email: null,
        whatsappContact: null,
        countryCode: null,
        employment: null,
        tellUs: null,
      },
      form: {
        startDate: null,
        endDate: null,
        guests: [],
      },
      roomGuests: null,
      numOfGuests: 1,
      currentStep: 1,
      currentGuest: 0,
      addingGuest: false,
      deletingGuest: false,
      editingGuest: false,
      loading: false,
      hasError: false,
      reservationCompleted: false,
      roomPeriods: [],
      shareBedroomPeriods: [],
      categoriesInfo: {
        "Price": {
          description: [
            "Price amount description",
            "This price does not include the costs of electricity and gas which vary upon consumption and will be communicated once the bills are issued by the specific providers",
            "This amount is due within the first 5 working days of the month via credit card or bank transfer"
          ],
          visible: false
        },
        "Deposit": {
          description: [
            "The amount relative to the security deposit shall be paid in advance. This amount shall be restituted once you leave the apartment and we have checked that it is  in the same conditions as initially delivered"
          ],
          visible: false
        },
        "Service Fees": {
          description: [],
          visible: false
        },
        "Total": {
          description: [
            "The security deposit and service fee need to be paid in order to finalize the booking. Rent will be paid once you enter the unit!"
          ],
          visible: false
        },
      },
      maxArrivalDate: null,
      maxDepartureDate: null,
      specialDateFormat: true,
      serviceFeesDescription: null,
    }
  },
  computed: {
    ...mapState({
      reservation: state => state.reservationStore,
      countries: state => state.countryStore.countries,
      user: state => state.userStore.user,
    }),
    reservationType() {
      return this.reservation.type
    },
    readonly() {
      return this.user && this.currentGuest === 0
    },
    guests() {
      return parseInt(this.roomGuests) || parseInt(this.reservation.guests) || '1'
    },
    nationalityTypes() {
      return nationalityTypes
    },
    extractedNationalities() {
      return this.countries.map(country => country.nationality)
    },
    extractedCountryCode() {
      // return this.countries.map(country => country.name + ' - ' + country.dial_code)
      return this.countries.map(country => country.name)
    },
    occupedPeriods() {
      return !this.isApartment ?
        this.roomPeriods.length
          ? this.roomPeriods
          : this.shareBedroomPeriods.filter(period => this.guests > period.guests)
        : []
    },
    isApartment() {
      return this.reservationType === accommodations.APARTMENT
    },
    wizardStyle() {
      return {
        '--color': this.isApartment ? '#67AEA6' : '#E26F5A'
      }
    },
    minStayMonth() {
      let addMonthCount = (this.item.minStayMonth != null) ? this.item.minStayMonth - 1 : 1
      return this.form.startDate ? moment(this.form.startDate).add(addMonthCount, 'months').format('YYYY-MM') : null
    }
  },
  async beforeMount() {
    if (this.reservationType === this.accommodations.APARTMENT) {
      this.currentStep = 2
      this.form.startDate = this.reservation.checkIn
      this.form.endDate = this.reservation.checkOut
      this.roomGuests = this.reservation.guests
    } else {
      if (this.reservation.item.type.isShared) {
        this.shareBedroomPeriods = await roomService.getSharedBedroomOccupedPeriods(this.reservation.item.buildingId)
      } else {
        this.roomPeriods = await roomService.getRoomTypeOccupedPeriods(this.reservation.item.buildingId, this.reservation.item.type.id)
      }

      this.roomGuests = 1
    }
    this.setMaxDates()

    if (!this.isApartment) {
      this.serviceFeesDescription = "There is a one-time service fee to be paid, which includes support and assistance throughout the entire stay, maintenance costs (so if there is any problem in the house, we fix it for you!). This amount considers VAT charge equal to 22%"
    } else {
      this.serviceFeesDescription = "There is a one-time service fee to be paid equal to one month rent, which includes contract registration costs, support during the entire stay, and monthly maintenance check ups which will be performed in the unit"
    }
    this.categoriesInfo['Service Fees'].description.push(this.serviceFeesDescription)

  },
  mounted() {
    this.form.guests = [...this.form.guests, this.user ? this.user : {}]
    this.guestFields = {...this.user}
  },
  watch: {
    roomGuests: {
      handler() {
        if (this.reservation.item.type.isShared) {
          this.form.startDate = null
          this.form.endDate = null
        }
      }
    },
    'form.startDate': {
      handler() {
        this.checkIfOccupedPeriod()
      }
    },
    'form.endDate': {
      handler() {
        this.checkIfOccupedPeriod()
      }
    }
  },
  methods: {
    openCategoryDescription(category) {
      for (const cat in this.categoriesInfo) {
        if (cat === category) {
          this.categoriesInfo[cat].visible = true
        } else {
          this.categoriesInfo[cat].visible = false
        }
      }
    },
    closeCategoryDescription(category) {
      this.categoriesInfo[category].visible = false
    },
    showModal(index) {
      this.$store.commit('carouselStore/setIndexSlide', index)
      this.$store.commit('carouselStore/setImagesList', this.item.images)
      this.$root.$emit('bv::show::modal', 'carousel-modal')
    },
    prevStep() {
      this.currentStep -= 1
    },
    async nextStep() {
      await this.bookingRequest()
    },
    nextMonth(date) {
      return date ? moment(date, 'YYYY-MM').add(1, 'month').format('YYYY-MM') : moment().format('YYYY-MM')
    },
    resetFields() {
      Object.keys(this.guestFields).forEach(key => this.guestFields[key] = null)
    },
    setGuest() {
      return {
        id: this.guestFields.id || null,
        firstname: this.guestFields.firstname,
        lastname: this.guestFields.lastname,
        birthDate: this.guestFields.birthDate,
        genre: this.guestFields.genre,
        nationality: this.guestFields.nationality,
        email: this.guestFields.email,
        whatsappContact: this.guestFields.whatsappContact,
        countryCode: this.guestFields.countryCode,
        employment: this.guestFields.employment,
        tellUs: this.guestFields.tellUs,
        isPrimary: this.currentGuest === 0,
        authenticationType: 'MAIL',
        authType: 'MAIL'
      }
    },
    checkIfOccupedPeriod() {
      const occuped = this.occupedPeriods.find(period => moment(period.date).isBetween(this.form.startDate, this.form.endDate))

      if (occuped) {
        this.$toasted.error(this.$i18n.t('Invalid dates'))

        this.$nextTick(() => {
          this.form.startDate = null
          this.form.endDate = null
        })

        return false
      }
    },
    async deleteGuest() {
      this.deletingGuest = true

      if (this.user) {
        if (this.form.guests[this.currentGuest].id && this.form.guests[this.currentGuest].id === this.user.id) {
          this.$toasted.error(this.$i18n.t(`Cannot delete logged user`))
          this.deletingGuest = false
          return false
        }
      }

      this.form.guests.splice(this.currentGuest, 1)
      this.numOfGuests -= 1

      if (this.currentGuest > 0) {
        this.currentGuest -= 1
      }

      this.editGuest(this.currentGuest)
    },
    editGuest(index) {
      if (index < this.form.guests.length) {
        if (!this.deletingGuest && !this.editingGuest) {
          this.form.guests[this.currentGuest] = this.setGuest()
        }

        this.$nextTick(() => {
          this.deletingGuest = false
        })

        this.currentGuest = index

        if (this.editingGuest) {
          this.form.guests[this.currentGuest] = this.setGuest()
        } else {
          this.editingGuest = true
          const guest = this.form.guests[this.currentGuest]

          if (guest) {
            this.guestFields = {...guest}
          }
        }

        this.editingGuest = false
      }
    },
    async addGuest() {
      if (this.editingGuest) {
        this.form.guests[this.currentGuest] = this.setGuest()
        this.currentGuest += 1
      }

      this.addingGuest = true

      const isValid = await this.$refs.validator.validate()

      if (isValid) {
        this.form.guests[this.currentGuest] = this.setGuest()

        this.numOfGuests += 1
        this.currentGuest += 1

        if (this.form.guests.length <= this.item.numOfPerson) {
          this.form.guests.push({})
        }

        this.resetFields()
      }

      this.addingGuest = false
    },
    async bookingRequest() {
      switch (this.currentStep) {
        case 1: {
          const startDate = moment(this.form.startDate, 'YYYY-MM')
          const endDate = moment(this.form.endDate, 'YYYY-MM')
          const isValid = await this.$refs.validator.validate()

          if (isValid) {
            if (!endDate.isSame(startDate) && !endDate.isAfter(startDate)) {
              this.$toasted.error(this.$i18n.t(`The dates are incorrect`))
              return false
            }

            this.currentStep += 1
          }

          break
        }

        case 3: {
          const isValid = await this.$refs.validator.validate()
          if (isValid) {

            if (this.form.guests.length < this.guests) {
              this.$toasted.error(this.$i18n.t(`Complete the data for each`, {guest: this.$i18n.t(`guest`)}))
              return false
            }

            await this.addGuest()

            this.form.guests = this.form.guests.filter(guest => Object.entries(guest).length !== 0)

            const bookingRequestData = {
              startDate: this.form.startDate,
              endDate: this.form.endDate,
              reservationUser: this.form.guests,
              accomodationTypeId: this.item.type.id
            }

            this.loading = true

            const {status} = this.reservationType === accommodations.ROOM
              ? await guestService.roomBookingRequest(bookingRequestData, this.$store.state.reservationStore.buildingId)
              : this.reservationType === accommodations.APARTMENT
                ? await guestService.apartmentBookingRequest(bookingRequestData, this.item)
                : null

            if (status !== 200) {
              this.hasError = true
              this.reservationCompleted = true
            }

            this.$store.commit('searchStore/setParams', null)
            this.loading = false
            this.currentStep += 1
          }

          break
        }

        default: {
          this.currentStep += 1
        }
      }
    },
    setMaxDates() {
      //DEPARTURE : 2 años despues de hoy
      let currentDate = new Date()
      let currentDateYear = currentDate.getFullYear()
      let currentDateMonth = currentDate.getMonth()
      let currentDateDay = currentDate.getDate()
      let maxCheckoutDate = new Date(currentDateYear + 2, currentDateMonth, currentDateDay)
      this.maxDepartureDate = maxCheckoutDate.toISOString().split("T")[0]

      //ARRIVAL : Cantidad de meses de estadia minima antes del departure
      let maxCheckOutDateYear = maxCheckoutDate.getFullYear()
      let maxCheckOutDateMonth = maxCheckoutDate.getMonth()

      //Si el minStayMonth no es null, se le resta al mes del maxCheckoutDate para la calcular el mes de maxArrivalDate. Sino, es toma este como 1 mes antes del mes de maxCheckoutDate
      let subtractMonthCount = (this.item.minStayMonth != null) ? this.item.minStayMonth - 1 : 1

      let maxCheckInDateAndTime = new Date(maxCheckOutDateYear, maxCheckOutDateMonth - subtractMonthCount, 1)
      this.maxArrivalDate = maxCheckInDateAndTime.toISOString().split("T")[0]
    }
  }
}
</script>

<style lang="scss" scoped>
.just-title {
  @include font-style($montserrat, $font-14);
}

.rf-wizard {

  .video-wrapper {
    /* Will not allow the video to overflow the
    container */
    overflow: hidden;

    /* Centering the container's content vertically
    and horizontally */
    //text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  video {
    /** Simulationg background-size: cover */
    object-fit: cover;
    height: 100%;
    width: 100%;

    position: absolute;
    top: 0;
    left: 0;
  }

  .cursor-pointer {
    cursor: pointer;
  }

  .description-icon {
    color: var(--color);
  }

  .wizard-header {
    display: flex;
    align-items: center;
    justify-content: space-around;
    background-color: $white;
    min-height: 60px;

    .step-name {
      @include font-style($montserrat, 'medium', $font-16);
      text-transform: uppercase;
      transition: color .3s;
      color: $gray;

      &.active {
        color: var(--color);
        font-weight: 600;
      }
    }
  }

  .wizard-content {
    background-color: $white;
    padding-top: 30px;

    .reservation-date,
    .reservation-summary,
    .reservation-details,
    .reservation-checkout {
      height: 100%;

      .step-title {
        @include font-style($montserrat, 'medium', $font-20);
        text-transform: uppercase;
        color: var(--color);

        &.checkout {
          @include font-style($montserrat-light, 'light', $font-20);
          margin-top: 30px;
          text-align: center;
        }
      }
    }

    .reservation-summary {
      .reservation-summary-header {
        padding-bottom: 40px;
        border-bottom: 1px solid $gray;
      }

      .accommodation {
        @include font-style($montserrat-bold, 'bold', $font-22);
        color: var(--color);

        &.subtitle {
          @include font-style($montserrat-light, 'light', $font-22);
        }
      }

      .reservation-guests,
      .reservation-period {
        @include font-style($montserrat, 'medium', $font-14);
        color: var(--color);

        .guest-icon {
          color: $black;
          margin: 0 5px;
          font-size: $font-12;
        }

        .period {
          color: $dark-blue;
        }
      }

      .reservation-amounts {
        padding: 30px 15px;

        .price-label,
        .price-deposit,
        .price-service-fees {
          display: flex;
          justify-content: space-between;

          .label {
            @include font-style($montserrat, 'medium', $font-14);
            color: $dark-blue;
          }

          .price {
            @include font-style($montserrat-bold, 'bold', $font-14);
            color: $dark-blue;
          }
        }
      }

      // .transform-building {
      //   padding: 20px 0;
      //   background-color: $dark-blue;
      //   color: $white;
      //   position: relative;
      //   z-index: 1;

      //   &:after {
      //     content: '';
      //     position: absolute;
      //     top: 0;
      //     left: 100%;
      //     width: 100vw;
      //     height: 100%;
      //     background-color: $dark-blue;
      //     z-index: 3;
      //     display: none;
      //   }
      // }
      .reservation-price {
        .reservation-total-price {
          .total-label {
            @include font-style($hind, 'light', $font-14);
            color: $dark-blue;
            text-transform: uppercase;
            line-height: 5px;
          }

          .total-price {
            @include font-style($hind, 'bold', $font-22);
            color: $dark-blue;
          }

          .total-month {
            @include font-style($montserrat, 'medium', $font-14);
            color: $dark-blue;
          }
        }
      }

      .make-booking-request {

        .make-request {
          @include font-style($montserrat-bold, 'bold', $font-14);
          color: $dark-blue;
          text-transform: uppercase;
          margin: 0;
        }

        .make-request-text {
          @include font-style($montserrat, 'medium', $font-10);
          color: $dark-blue;
          text-transform: uppercase;
          margin: 0;
        }
      }
    }

    .reservation-details {

      .add-guest {
        @include font-style('hind-bold', 'medium', $font-14);
        color: var(--color);
        padding: 15px 0;
        border-top: 1px solid $beige;
        cursor: pointer;
        display: flex;
        align-items: center;

        .add-guest-icon {
          margin-right: 5px;
          font-size: $font-12;
        }
      }

      .added-guests {
        .added-guest-icon {
          margin-left: 5px;
          color: var(--color);
          cursor: default;

          &.added {
            color: var(--color);
            cursor: pointer;
          }
        }
      }

      .delete-guest {
        @include font-style($montserrat, 'medium', $font-14);
        display: flex;
        align-items: center;
        cursor: pointer;

        .delete-guest-label {
          margin-left: 5px;
        }
      }

      .loader-container {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 100px 0;

        .loader-label {
          @include font-style($montserrat, 'semibold', $font-20);
          color: var(--color);
        }
      }
    }

    .reservation-checkout {
      .checkout-title {
        @include font-style($montserrat-bold, 'bold', $font-22);
        color: $dark-blue;
        text-align: center;
        margin-top: 50px;
      }
    }
  }

  .wizard-footer {
    background-color: var(--color);
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 100px;

    padding-left: 0;

    &.last-step {
      height: 40%;
      justify-content: center;
    }

    .verify-email {
      @include font-style($montserrat, 'semibold', $font-16);
      color: $white;
      text-align: center;
      width: 100%;
    }
  }

  .description {
    @include font-style($montserrat-light, 'light', $font-12);
    color: $dark-blue;
    margin-top: 15px;
    padding-bottom: 30px;
    border-bottom: 1px solid $dark-gray;
  }
}

@media screen and (min-width: map-get($grid-breakpoints, 'lg')) {
  .rf-wizard {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .wizard-header,
    .wizard-footer {
      position: relative;
      z-index: 10;

      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 100%;
        width: 100vw;
        height: 100%;
        background-color: inherit;
        z-index: 10;
      }
    }

    .wizard-header {
      position: relative;
    }

    .wizard-content {
      position: relative;
      height: 100%;
      width: 100%;
      padding-left: 25px;
      overflow-x: hidden;
      overflow-y: auto;
      z-index: 5;

      &:after {
        content: '';
        position: fixed;
        top: 0px;
        left: 50%;
        width: 100vw;
        height: 100%;
        background-color: #fff;
        z-index: -1;
      }

      // .reservation-date {
      // }
      // .reservation-summary {

      // }
      // .reservation-details {

      // }
      .reservation-checkout {
        .checkout-title {
          margin-top: 200px;
        }
      }
    }

    .wizard-footer {
      padding-left: 30px;
    }
  }
}
</style>
