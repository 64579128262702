<template>
  <b-modal :ref="modalId" :id="modalId" hide-footer @show="showIndexSlide()">
    <VueperSlides fixed-height="85vh" arrows-outside fractions :touchable="false">
      <VueperSlide v-for="(image, index) in imageArray" :key="index">
        <template v-bind:class="[ getFormat(image.pathname)==='mp4' ? 'video-container' : '']" #content>
          <b-img v-if="getFormat(image.pathname)!=='mp4'"
                 :src="image.largeImageFilepath || null | getAsset"
                 class="image_fluid sliderCursor centered"/>
          <video v-if="getFormat(image.pathname)==='mp4'" autoplay muted loop playsinline
                 class="sliderCursor image_fluid centered">
            <source :src="getImage(image.pathname)" type="video/mp4">
          </video>
        </template>
      </VueperSlide>
    </VueperSlides>
  </b-modal>
</template>

<script>
import {mapState} from 'vuex'
import {getAsset} from '@/helpers'


export default {
  name: 'CarouselModal',
  props: {
    reservation: Object,
    index: {
      type: Number,
      default: -1
    }
  },
  data() {
    return {
      modalId: 'carousel-modal',
      imageArray: [],
    }
  },
  methods: {
    showIndexSlide() {
      let obj = this.imagesList[this.indexSlide]
      this.imageArray = this.imagesList;
      this.imageArray.splice(this.indexSlide, 1);
      this.imageArray.unshift(obj)
    },
    getImage(image) {
      return getAsset(image)
    },
    getFormat(image) {
      return image.split('.')[1]
    },
  },
  computed: {
    ...mapState({
      indexSlide: state => state.carouselStore.indexSlide,
      imagesList: state => state.carouselStore.imagesList,
    })
  },
  beforeUnmount() {
    this.$store.commit('carouselStore/setOpenModal', false)
  },
  beforeMount() {
    if (this.index !== -1) {
      this.modalId += `-${this.index}`
    }
  }
}
</script>

<style css>

.video-container {
  position: relative;
}

.video-container video {
  width: 100%;
  height: 100%;
  position: absolute;
  object-fit: cover;
  z-index: 0;
}

.modal-header {
  background-color: transparent !important;
  padding: 0px !important;
  margin: 0px !important;
  border: 0px !important;
}

.image_fluid {
  object-fit: scale-down;
  max-width: 100%;
  max-height: 100%;
}

.vueperslides__arrow {
  color: white;
}

.modal-dialog {
  max-width: 95vw;
  max-height: 95vh;
  padding: 5px;
}

.modal-content {
  text-align: center !important;
  border-radius: 0 !important;
  border: 0px solid white !important;
  background-color: transparent !important;
}

.modal-header {
  background-color: #24425b;
  color: white;
  border-radius: 0;
}

.modal .close {
  outline: none;
  color: white;
}

.vueperslide--loading .vueperslide__content-wrapper {
  display: none !important;
}

</style>
