<template>
  <div class="booking-request" :style="{ backgroundImage: `url(${background})`}">
    <b-container>
      <b-row>
        <b-col cols="12" lg="4" class="pt-2">
          <router-link to="/">
            <img src="@/assets/images/logo.png" />
          </router-link>
        </b-col>
        <b-col class="wizard-container mt-5 mt-lg-0" cols="12" lg="8">
          <RFWizard class="booking-request-wizard" :item="item" />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import RFWizard from '@/components/wizard/RFWizard'
import { store } from '@/stores'
import { getAsset } from '@/helpers'
import { mapGetters } from 'vuex'

export default {
  name: 'BookingRequestPage',
  components: {
    RFWizard,
  },
  beforeRouteEnter(to, from, next) {
    if (!store.state.reservationStore.item) {
      next({ name: 'homepage' })
    } else {
      next()
    }
  },
  data() {
    return {
      item: null,
    }
  },
  beforeMount() {
    this.item = this.$store.state.reservationStore.item
    this.checkOverflow()
  },
  destroyed() {
    document.querySelector('body').classList.remove('overflow-hidden')
  },
  computed: {
    ...mapGetters('windowStore', ['isLg']),
    background() {
      const primaryImgIndex = this.item.images.findIndex(image => image.isPrimary);
      const index = primaryImgIndex == -1 ? 0 : primaryImgIndex;
      return this.item && this.item.images.length ? getAsset(this.item.images[index].pathname) : null
    }
  },
  mounted() {
    window.addEventListener('resize', () => this.checkOverflow())
  },
  methods: {
    checkOverflow() {
      const body = document.querySelector('body')

      if (this.isLg) {
        if (!body.classList.contains('overflow-hidden')) {
          body.classList.add('overflow-hidden')
        }
      } else {
        if (body.classList.contains('overflow-hidden')) {
          body.classList.remove('overflow-hidden')
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.booking-request {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 100vh;

  .wizard-container {
    .booking-request-wizard {
      height: 100%;
      padding-bottom: 100px;
    }
  }
}

@media screen and (min-width: map-get($grid-breakpoints, 'lg')) {
  .booking-request {
    height: 100vh;

    .wizard-container {
      height: 100vh;

      .booking-request-wizard {
        height: 100%;
        padding-bottom: 0;
      }
    }
  }
}
</style>
