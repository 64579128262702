<template>
  <div class="rf-month-picker" :style="monthPickerStyle" ref="monthPicker">
    <RFInput :label="label" v-model="formatDate" :color="color" readonly @focusin="focusIn" @focusout="focusOut"/>
    <div class="calendar" v-if="openCalendar">
      <b-row class="year">
        <b-col>
          <div class="prev cursor-pointer" @click="prevYear" v-if="min ? selectedYear > minYear : true">
            <FontAwesomeIcon :icon="['fas', 'angle-left']"/>
          </div>
        </b-col>
        <b-col class="cursor-pointer text-center">
          {{selectedYear}}
        </b-col>
        <b-col class="d-flex justify-content-end">
          <div class="next cursor-pointer" @click="nextYear" v-if="max ? selectedYear < maxYear : true">
            <FontAwesomeIcon :icon="['fas', 'angle-right']"/>
          </div>
        </b-col>
      </b-row>
      <b-container>
        <b-row class="months">
          <b-col cols="4" class="month" v-for="(month, index) in months" :key="index"
          :class="{
            current: month.fullDate === `${currentYear}-${currentMonth}-01`,
            selected: month.fullDate === date,
          }"
          @click="onSelectDate(month.fullDate)">
            <span class="month-label"
              :class="{
                disabled: isDisabled(month.fullDate),
                occuped: isOccuped(month.fullDate),
              }">
              <template v-if="truncatedMonth">{{$t(month.label) | truncate(0, 3)}}</template>
              <template v-else>{{$t(month.label)}}</template>
            </span>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>

<script>
import RFInput from '@/components/forms/RFInput'
import moment from 'moment'

export default {
  components: {
    RFInput,
  },
  props: {
    label: {
      type: String,
      default: null,
    },
    color: {
      type: String,
      default: '#24425B',
    },
    value: {
      type: String,
      default: null,
    },
    background: {
      type: String,
      default: '#fff',
    },
    format: {
      type: String,
    },
    truncatedMonth: {
      type: Boolean,
      default: true,
    },
    min: {
      type: String,
      default: null,
    },
    max: {
      type: String,
      default: null,
    },
    occupedPeriods: {
      type: Array,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    specialFormat: {
      type: Boolean,
      default: false
    },
    endingDate: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      date: null,
      openCalendar: false,
      currentMonth: null,
      currentYear: null,
      selectedYear: null,
      dirtyDate: false,
      months: [
        { label: 'January', number: '01' },
        { label: 'Febrary', number: '02' },
        { label: 'March', number: '03' },
        { label: 'April', number: '04' },
        { label: 'May', number: '05' },
        { label: 'June', number: '06' },
        { label: 'July', number: '07' },
        { label: 'August', number: '08' },
        { label: 'September', number: '09' },
        { label: 'October', number: '10' },
        { label: 'November', number: '11' },
        { label: 'December', number: '12' },
      ],
      dateFormats: {
        it: {
          default: 'MM/YYYY',
          special: 'DD/MM/YYYY'
        },
        es: {
          default: 'MM/YYYY',
          special: 'DD/MM/YYYY'
        },
        en: {
          default: 'YYYY/MM',
          special: 'DD/MM/YYYY'
        }
      }
    }
  },
  computed: {
    monthPickerStyle() {
      return {
        '--color': this.color,
        '--background': this.background,
      }
    },
    formatDate() {
      if(!this.date){
        return null
      }
      let format = this.dateFormats[this.$i18n.locale].default
      //Check if is special format
      if(this.specialFormat){
        format = this.dateFormats[this.$i18n.locale].special
      }
      let returnDate = moment(this.date,'YYYY-MM-DD').format(format)
      //Check if is ending date
      if(this.endingDate){
        returnDate = moment(this.date,'YYYY-MM-DD').endOf('month').format(format)
      }
      return returnDate
    },
    pickerDisabled() {
      return this.disabled
    },
    minYear() {
      return this.min ? this.min.split('-')[0] : null
    },
    minMonth() {
      return this.min ? this.min.split('-')[1] : null
    },
    maxYear() {
      return this.max ? this.max.split('-')[0] : null
    },
    maxMonth() {
      return this.max ? this.max.split('-')[1] : null
    }
  },
  mounted() {
    this.date = this.value
    this.currentYear = this.selectedYear = moment().format('YYYY')
    this.currentMonth = moment().format('MM')

    this.$nextTick(() => this.dirtyDate = false)
  },
  methods: {
    prevYear() {
      this.selectedYear = parseInt(this.selectedYear) - 1
      this.updateCalendar()
      this.focusOut(true)
    },
    nextYear() {
      this.selectedYear = parseInt(this.selectedYear) + 1
      this.updateCalendar()
      this.focusOut(true)
    },
    onSelectDate(date) {
      const isDisabled = this.isDisabled(date)
      const isOccuped = this.isOccuped(date)

      if (!isDisabled && !isOccuped && !this.pickerDisabled) {
        this.selectDate(date)
      }
    },
    selectDate(date) {
      this.date = date
      this.openCalendar = false
      this.dirtyDate = true
      this.$emit('input', this.date)
    },
    isBetweenDate(date) {
      return this.isAfterDate(date) && this.isBeforeDate(date)
    },
    isAfterDate(date) {
      return moment(date, 'YYYY-MM-DD').isSameOrAfter(this.min)
    },
    isBeforeDate(date) {
      return moment(date, 'YYYY-MM-DD').isSameOrBefore(this.max)
    },
    isDisabled(date) {
      return this.min && this.max
              ? !this.isBetweenDate(date)
              : this.min && !this.max
                ? !this.isAfterDate(date)
                : !this.min && this.max
                  ? !this.isBeforeDate(date)
                  : false
    },
    isOccuped(date) {
      if (!this.occupedPeriods) {
        return false
      }

      date = moment(date, 'YYYY-MM-DD')

      return this.occupedPeriods.find(period => {
        const startDate = moment(period.date, 'YYYY-MM-DD')
        return date.isSame(startDate)
      })
    },
    focusIn() {
      this.openCalendar = !this.openCalendar
      this.updateCalendar()
    },
    focusOut(openCalendar = false) {
      window.setTimeout(() => {
        this.openCalendar = openCalendar
      }, 200)
    },
    updateCalendar() {
      this.months.forEach(month => month.fullDate = moment(`${this.selectedYear}-${month.number}`, 'YYYY-MM').format('YYYY-MM-DD'))
    }
  },
  watch: {
    value: {
      handler() {
        this.date = this.value
      }
    },
    date: {
      handler() {
        this.updateCalendar()
      }
    },
    selectedYear: {
      handler() {
        this.dirtyDate = true
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.rf-month-picker {
  position: relative;
  margin-top: 20px;
  width: 100%;
  background-color: var(--background);

  .calendar {
    border: 1px solid var(--color);
    padding: 10px;
    position: absolute;
    top: 32px;
    background-color: $white;
    z-index: 30;
    width: 100%;

    .year {
      align-items: center;
      justify-content: space-between;

      .prev,
      .next {
        width: fit-content;
        padding: 0 7px;
        transition: background-color .3s;
        border-radius: 3px;

        &:hover {
          background-color: var(--color);
          color: $white;
        }
      }
    }
    .months {
      .month {
        padding: 10px 0;
        border-radius: 3px;
        border: 1px solid transparent;
        text-align: center;
        cursor: pointer;
        transition: background-color .3s, border .3s;

        &:hover {
          border: 1px solid var(--color);
        }
        &.current {
          background-color: rgba($gray, .5);
        }
        &.selected {
          background-color: var(--color);
          color: $white;
        }
        .month-label {
          @include font-style($montserrat, 'medium', $font-14);

          &.disabled {
            color: $gray;
          }
          &.occuped {
            color: $danger;
          }
        }
      }
    }
  }
}
</style>
